import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Donate from '../components/Donate';
import Header from '../components/Header';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Content, { HTMLContent } from '../components/Content';
import RouterTracker from '../components/RouterTracker';

import ThemeContext from '../context/ThemeContext';
import Button from '../components/Button';

const DonatePageTemplateStyles = styled.article(
  (props) => `
  padding: ${props.theme.size.base * 13}px 
    ${props.theme.size.base * 4}px 
    ${props.theme.size.base * 4}px;
  ${
    (props.breakpoints.lg || props.breakpoints.xl) &&
    `
   padding: ${props.theme.size.base * 8}px;
  `
  };
  .body {
    position: relative;
    z-index: 500;
    max-width: ${props.theme.size.base * 128}px;
    margin: 0 auto;
    &.header-image {
      padding-top: 20vh;
    }
  }
  .donate-container {
    margin-bottom: ${props.theme.size.base * 6}px;
  }
  .button-set {
    display: flex;
    flex-wrap: wrap;
    gap: ${props.theme.size.base * 2}px;
  }
`
);

export const DonatePageTemplate = ({
  image,
  title,
  description,
  content,
  contentComponent,
  donateCar,
  donate,
  recurring,
  breakpoints,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <DonatePageTemplateStyles theme={theme} breakpoints={breakpoints}>
          <RouterTracker />
          <div>
            <section className='typesetter section'>
              <h1 className='title'>{title}</h1>
              <p>{description}</p>
              <PageContent content={content} />
              <div className='donate-container'>
                <h2 className='has-text-weight-semibold'>{donate.heading}</h2>
                <p>{donate.description}</p>
                <div className='button-set'>
                  {donate.options.map((price) => (
                    <Button
                      key={price.plan}
                      href={`${price.url}?amount=${price.price}&recurring=0`}
                      target='_blank'
                      rel='noopener noreferrer'
                      label={`Donate $${price.price}`}
                      color='tertiary'
                    >
                      {isNaN(price.price) ? price.price : `$ ${price.price}`}
                    </Button>
                  ))}
                </div>
              </div>
              <div className='donate-container'>
                <h2 className='has-text-weight-semibold'>
                  {recurring.heading}
                </h2>
                <p>{recurring.description}</p>
                <div className='button-set'>
                  {recurring.options.map((price) => (
                    <Button
                      key={price.plan}
                      href={`${price.url}?amount=${price.price}&recurring=1`}
                      target='_blank'
                      rel='noopener noreferrer'
                      label={`Monthly donation of $${price.price}`}
                      color='tertiary'
                    >
                      {isNaN(price.price) ? price.price : `$ ${price.price}`}
                    </Button>
                  ))}
                </div>
              </div>
              {donateCar && (
                <div className='donate-container'>
                  {donateCar.heading && (
                    <h2 className='has-text-weight-semibold'>
                      {donateCar.heading}
                    </h2>
                  )}
                  {donateCar.description && <p>{donateCar.description}</p>}
                  {donateCar.buttonUrl && (
                    <div className='button-set'>
                      <Button
                        href={donateCar.buttonUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                        color='tertiary'
                      >
                        {donateCar.buttonText}
                      </Button>
                    </div>
                  )}
                </div>
              )}
              <div>
                <h2 className='has-text-weight-semibold'>Buy Our Stuff</h2>
              </div>
            </section>
          </div>
        </DonatePageTemplateStyles>
      )}
    </ThemeContext.Consumer>
  );
};

DonatePageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  donateCar: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    buttonUrl: PropTypes.string,
    buttonText: PropTypes.string,
  }),
  donate: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    options: PropTypes.array,
  }),
  recurring: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    options: PropTypes.array,
  }),
};

const DonatePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const { markdownRemark: post } = data;
  const breakpoints = useBreakpoint();

  return (
    <DonatePageTemplate
      image={frontmatter.image}
      title={frontmatter.title}
      description={frontmatter.description}
      contentComponent={HTMLContent}
      content={post.html}
      donateCar={frontmatter.donateCar}
      donate={frontmatter.donate}
      recurring={frontmatter.recurring}
      breakpoints={breakpoints}
    />
  );
};

DonatePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default DonatePage;

export const DonatePageQuery = graphql`
  query DonatePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        donateCar {
          heading
          description
          buttonUrl
          buttonText
        }
        donate {
          heading
          description
          options {
            description
            items
            plan
            price
            url
          }
        }
        recurring {
          heading
          description
          options {
            description
            items
            plan
            price
            url
          }
        }
      }
    }
  }
`;
