import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import ThemeContext from '../context/ThemeContext';

import Button from './Button';

const DonateStyles = styled.div(
  (props) => `
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: ${props.theme.size.base * -1}px;
  ${
    (props.breakpoints.md || props.breakpoints.lg || props.breakpoints.xl) &&
    `
    flex-direction: row;
    `
  }
`
);

const Plan = styled.div(
  (props) => `
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  .plan-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .plan-details {
    flex-grow: 1;
    margin: ${props.theme.size.base * 1}px;
    padding: ${props.theme.size.base * 4}px;
    border-radius: ${props.theme.size.base * 1}px; 
    ${
      props.theme.dark
        ? `
        background: ${props.theme.color.background[15]};
        background: linear-gradient(
          20deg,
          ${props.theme.color.background[65]},
          ${props.theme.color.background[45]}
        );
      `
        : `
      background: ${props.theme.color.background[90]};
      background: linear-gradient(
        20deg,
        ${props.theme.color.background[95]},
        ${props.theme.color.background[85]}
      );
      `
    };
    h3 {
      font-size: ${props.theme.size.fontSize[6]};
      line-height: ${props.theme.size.lineHeight[6]};
      margin-bottom: ${props.theme.size.base * 2}px;
      a {
        text-decoration: none;
        color: ${
          props.theme.dark
            ? props.theme.color.primary[65]
            : props.theme.color.primary[30]
        };
        transition: color 450ms ease;
        &:hover {
          color: ${
            props.theme.dark
              ? props.theme.color.secondary[75]
              : props.theme.color.secondary[45]
          };
        }
      }
    }
    .actions {
      margin-bottom: ${props.theme.size.base * 2}px;
    }
    .description {
      p {
        margin-bottom: ${props.theme.size.base * 0.5}px;
        font-size: ${props.theme.size.fontSize[2]};
        line-height: ${props.theme.size.lineHeight[2]};
      }
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
          position: relative;
          margin-left: ${props.theme.size.base * 2}px;
          font-size: ${props.theme.size.fontSize[2]};
          line-height: ${props.theme.size.lineHeight[2]};
          color: ${
            props.theme.dark
              ? props.theme.color.primary[60]
              : props.theme.color.foreground[10]
          };
          transition: color 450ms ease;
          &:before {
            content: '⚬';
            position: absolute;
            left: ${props.theme.size.base * -1.75}px;
          }
        }
      }
    }
  }
  ${
    (props.breakpoints.lg || props.breakpoints.xl) &&
    `
    flex: 0 0 50%;
    `
  };
`
);

const Donate = ({ data }) => {
  const breakpoints = useBreakpoint();
  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <DonateStyles theme={theme} breakpoints={breakpoints}>
          {data.map((price) => (
            <Plan key={price.plan} theme={theme} breakpoints={breakpoints}>
              <div className='plan-details'>
                <div className='plan-header'>
                  <h3>
                    <a
                      href={`${price.url}?amount=${price.price}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {price.plan}
                    </a>
                  </h3>
                  <div className='actions'>
                    <Button
                      href={`${price.url}?amount=${price.price}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      label={`Donate $${price.price}`}
                      color='tertiary'
                    >
                      {isNaN(price.price) ? price.price : `$ ${price.price}`}
                    </Button>
                  </div>
                </div>
                <div className='description'>
                  <p>{price.description}</p>
                  {price.items && (
                    <ul>
                      {price.items.map((item) => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </Plan>
          ))}
        </DonateStyles>
      )}
    </ThemeContext.Consumer>
  );
};

Donate.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      plan: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      description: PropTypes.string,
      url: PropTypes.url,
      items: PropTypes.array,
    })
  ),
};

export default Donate;
